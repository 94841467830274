<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12 card p-1">
        <div>
          <div class="h2" style="color: #558cef">ประเภทหลักฐาน</div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1>เพิ่มประเภทหลักฐาน</b-button>
            </div>

            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>
                    <!-- <b-dropdown-item v-b-modal.modal-Import>
                      <feather-icon icon="FileTextIcon" class="mr-50" />
                      <span>Import Excel</span>
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Export Excel
                    </b-dropdown-item>
                    <!-- <b-dropdown-item>
                      <feather-icon icon="ClipboardIcon" class="mr-50" />
                      <span>Pdf</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <b-modal id="modal-Import" title="Import Excel" ok-title="ตกลง" cancel-title="ยกเลิก" no-close-on-backdrop>
            <b-card-text>
              <h5>นำเข้าไฟล์ Excel</h5>
              <b-form-file type="file" @change="onFileChange" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
            </b-card-text>
          </b-modal>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              theme="my-theme"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'edit'" class="text-nowrap">
                  <b-button variant="outline-info" v-b-modal="`modal-edit${props.index}`">
                    <feather-icon icon="Edit3Icon" size="16" class="mx-1" />
                  </b-button>
                  <b-modal
                    :id="`modal-edit${props.index}`"
                    cancel-variant="outline-secondary"
                    ok-title="บันทึก"
                    cancel-title="ยกเลิก"
                    title="แก้ไขประเภทหลักฐาน"
                    size=""
                    @ok="edit_data(props.row)"
                    no-close-on-backdrop
                  >
                    <b-form>
                      <div>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ประเภทหลักฐาน:">
                          <template v-slot:label> ประเภทหลักฐาน <span class="text-danger"> *</span> </template>
                          <b-form-input id="basicInput" v-model="props.row.evidence" placeholder="ประเภทหลักฐาน" />
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-modal>
                </span>
                <span v-else-if="props.column.field === 'delete'" class="text-nowrap">
                  <b-button variant="outline-danger" @click="dateField(props.row)">
                    <feather-icon icon="Trash2Icon" size="16" class="mx-1" />
                  </b-button>
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => handlePageChange(value)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- //model -->
      <div>
        <b-modal
          id="modal-1"
          cancel-variant="outline-secondary"
          ok-title="บันทึก"
          cancel-title="ยกเลิก"
          title="ประเภทหลักฐาน"
          size="md"
          @ok="getdata"
          no-close-on-backdrop
        >
          <b-form>
            <div>
              <b-form-group label-cols="4" label-cols-lg="4" label="ประเภทหลักฐาน:">
                <template v-slot:label> ประเภทหลักฐาน <span class="text-danger"> *</span> </template>
                <b-form-input id="basicInput" v-model="evidenceTypes" placeholder="ประเภทหลักฐาน" />
              </b-form-group>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BFormGroup, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
import { BFormInput, BRow, BCol } from 'bootstrap-vue';
import { BFormFile } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import readXlsxFile from 'read-excel-file';

export default {
  name: 'workgroup',
  components: {
    VueGoodTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormGroup,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    readXlsxFile,
  },

  data() {
    return {
      show: false,
      evidenceTypes: '',

      pageLength: 50,
      total: '',
      columns: [
        {
          label: 'ประเภทหลักฐาน',
          field: 'evidence',
        },
        {
          label: 'แก้ไข',
          field: 'edit',
          width: '5%',
        },
        {
          label: 'ลบ',
          field: 'delete',
          width: '5%',
        },
      ],
      rows: [],
      searchTerm: '',
      striped: false,
      bordered: true,
      borderless: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: true,
      footClone: true,
      headVariant: null,
      tableVariant: 'none',
      noCollapse: false,
      Excel: [],
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    this.show = true;
    const { access_token } = await this.access_token();
    const url = `${API}evidenceTypes?_page=1&_limit=100&_sort=-1`;
    const header = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res = await axios.get(url, header);

    this.rows = res.data.message.data;
    this.total = res.data.message.total;
    this.show = false;
  },

  methods: {
    dateField: async function (props) {
      console.log(props);
      const { id, evidence } = props;
      console.log(id, evidence);

      Swal.fire({
        title: 'คุณต้องการจะลบข้อมูลใช่หรือไม่',
        text: `ประเภทหลักฐาน ${evidence}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const { access_token } = await this.access_token();
          const url = `${API}evidenceTypes/${id}`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          const res = await axios.delete(url, head);
          console.log(res.data.message);
          
          if (res.data.message === 'delete Complete') {
            Swal.fire({
              icon: 'success',
              title: 'ลบข้อมูลสำเร็จ!',
              text: `รายการนี้ ถูกลบเเล้ว `,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          } else {
            Swal.fire({
              icon: 'danger',
              title: 'ลบข้อมูลไม่สำเร็จ!',
              text: `${res.data.message}`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }

        }
        this.handlePageChange();
      });

      return;

      // console.log(res);
    },

    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        // console.log('rows:', rows);
        this.Excel = rows;
      });
    },
    async edit_data(value) {
      // console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes/${value.id}`;
      const data = {
        evidence: value.evidence,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.put(url, data, head);
      this.handlePageChange();
      // console.log(res);
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'ประเภทหลักฐาน',
        columns: [
          {
            title: 'ประเภทหลักฐาน',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'ประเภทหลักฐาน',
                field: 'evidence',
              },
            ],
          },
        ],
      });
    },
    async getdata() {
      let { access_token } = await this.access_token();
      let url = `${API}evidenceTypes`;
      let data = {
        evidence: this.evidenceTypes,
      };
      let head = {
        headers: {
          Authorization: await access_token,
        },
      };
      if (this.rows === null && this.rows === undefined && this.rows === '' && this.rows === [] && this.rows === {}) {
        const res = await axios.post(url, data, head);
        // console.log(res.data.message);
        if (res.data.status === 200) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'บันทึกข้อมูล',
            showConfirmButton: false,
            timer: 1500,
          });
          this.handlePageChange();
        } else {
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.evidenceTypes.replace(' ', '');
        const QC = this.rows.find(({ unit }) => unit === this.evidenceTypes);
        if (QC) {
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: `ข้อมูลซ้ำ ${QC.unit} - ${QC.name}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          const res = await axios.post(url, data, head);
          // console.log(url);
          // console.log(data);
          // console.log(head);
          // console.log(res.data.message);
          if (res.data.status === 200) {
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'บันทึกข้อมูล',
              showConfirmButton: false,
              timer: 1500,
            });
            this.handlePageChange();
          } else if (res.data.message === 'already have this information') {
            Swal.fire({
              position: 'top-center',
              icon: 'error',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }
    },
    async handlePageChange(currentPage) {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}evidenceTypes?_page=${currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      this.rows = res.data.message.data;
      this.total = res.data.message.total;
      this.show = false;
    },

    async access_token() {
      //get access_token
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
